.root {
  overflow-y: hidden;
}

.content {
  position: relative;
}

.swoosh {
  margin-top: -1.25%;
  transform-origin: 50% 50%;
}

.mirrorY {
  margin-top: 0;
  margin-bottom: -1.25%;
  transform: scaleY(-1);
}

.mirrorX {
  transform: scaleX(-1);

  &.mirrorY {
    transform: scale(-1);
  }
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  &::before {
    position: absolute;
    /**  pick an insane value just to be sure */
    top: -600%;
    right: 0;
    left: 0;
    width: 100%;
    height: 600%;
    background-color: currentColor;
  }

  &.useInherit::before {
    background-color: inherit;
  }

  &.center {
    top: 50%;
    /** try to center the SVG vertically, might be weird in low elements? */
    transform: translateY(-50%);

    &::before {
      content: '';
    }
  }

  &.bottom {
    top: auto;
    bottom: 0;

    &::before {
      content: '';
    }
  }
}
