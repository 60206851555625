.infoBox {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: 650px;
  padding: 4rem 1rem 2.5rem 1rem;
  margin-top: 2rem;
  text-align: center;
  background-color: var(--dunesea-sand);

  .link {
    padding-top: 1rem;
    color: var(--denim);
  }
}

.CTABox {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 2rem;
  margin: 0 auto;
}

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 800px;
  margin: 0 auto;
}
