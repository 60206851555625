.imageBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: spacing(3);
  border-radius: 8px;
}

.bookingIntroBackground {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .title {
    position: absolute;
    top: 0;
    padding: spacing(3);
    font-size: 32px;
    line-height: 38.4px;

    @media ($tablet-landscape-down) {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.5%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .buttonWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: spacing(2);
    padding-bottom: spacing(7);

    @media ($tablet-landscape-down) {
      padding: spacing(3);
    }

    .button {
      max-width: 327px;
      font-size: 14px;
      opacity: 1;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}

.button {
  width: 100%;
  border-radius: 4px;
  box-shadow: var(--light-drop-shadow);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: var(--strong-drop-shadow);
  }
}

.introContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.maxWidthWrapper {
  max-width: 600px;
  margin: 0 auto;
}

.desktopIntro {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 360px;
    height: 620px;
  }
}

.phoneOnly {
  @media ($tablet-landscape-up) {
    display: none;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.desktopOnly {
  display: none;

  @media ($tablet-landscape-up) {
    display: block;
  }
}

.overflowYSwooshBlock {
  overflow-y: visible;
}

.swooshMobileBlock {
  overflow-y: revert;
  background-color: inherit !important;
}

.intro {
  display: flex;
  justify-content: center;

  &.mobile {
    padding-bottom: spacing(2);
  }
}

.heroContainer {
  display: flex;
  column-gap: spacing(2);
  align-items: center;
}

.heroMaxWidth {
  max-width: 400px;
}

.servicesIcon {
  height: fit-content;
}

.servicesBlock {
  padding: spacing(2) 0;
}

.link {
  margin-top: spacing(2);
  font-size: 14px;
  font-weight: 400;
  color: black;
  text-decoration: underline;
}

.iconBlock {
  display: flex;
  flex-direction: row;
  column-gap: spacing(1);
  align-items: center;
  margin-bottom: spacing(0.75);
}

.serviceIcon {
  width: 14px;
  height: fit-content;
}

.serviceName {
  font-size: 14px;
}

.summary {
  font-size: 14px;
}

.serviceBlock {
  margin: spacing(4) 0;
}

.clinicDescription {
  font-size: 14px;

  p + p {
    margin-top: spacing(1);
  }

  a {
    color: var(--black);
    text-decoration: underline;
  }
}

.servicesIconPart {
  display: flex;
  column-gap: spacing(1);

  .clinicTitle {
    margin-bottom: spacing(1);
    font-size: 14px;
    line-height: 23px;
  }

  img {
    width: 24px;
    height: auto;
  }
}

.bookingIntroContainer {
  @media ($tablet-landscape-up) {
    min-width: unset;
    max-width: unset;
    padding: unset;
    margin: unset;
  }
}

.bookingIntroTitle {
  font-size: 18px;
}

.bookingIntroDescription {
  font-size: 14px;
}

.iconFlexContainer {
  display: flex;
  flex-direction: row;
  column-gap: spacing(2);
  align-items: flex-start;
  justify-content: space-between;
}

.bookingIntroIcon {
  min-width: 48px;
  margin-right: spacing(1);
  margin-left: spacing(2);
}
