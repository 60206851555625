.root {
  display: inline-block;
  width: 65px;
}

.path {
  opacity: 0;
  transition: opacity 1s;

  &.in {
    opacity: 1;
  }
}

.text {
  position: absolute;
  font-family: var(--fontFamily-sofia);
  font-size: 250%;
  font-weight: var(--bold);
  fill: var(--black);
}
