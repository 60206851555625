.videoContainer {
  position: relative;
  padding: 56.25% 0 0 0;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coverContainer {
  position: relative;

  > span,
  img {
    object-fit: contain;
    max-width: 100%;
    height: 100%;
  }
}

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 68px;
  height: 68px;
  padding: 2px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
    fill: var(--soft-green);
    transition: fill 100ms ease;
  }

  &:hover {
    svg {
      fill: #449166;
    }
  }
}
