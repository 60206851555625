.root {
  --border: 1px solid var(--grey-chewie);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: spacing(4) 0;
  border-top: var(--border);
  border-bottom: var(--border);

  > * {
    margin: 0;
  }

  & + & {
    border-top: none;
  }

  @media ($desktop-up) {
    padding: spacing(4, 8);
    border-top: none;
    border-bottom: none;

    & + & {
      border-left: var(--border);
    }

    &:nth-last-child(n + 2) ~ div:last-child {
      @media ($desktop-up) {
        &::before,
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          content: '';
          background: var(--grey-chewie);
        }

        &::after {
          top: auto;
          bottom: 0;
        }
      }
    }

    &:only-child {
      @media ($desktop-up) {
        max-width: var(--content-max-width-desktop);
        padding: spacing(4) var(--sideMargin);
        margin: 0 auto;
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: spacing(2);

  svg {
    flex-shrink: 0;
    margin-left: 1em;
  }
}

.inner {
  display: flex;
  flex: 1;
  flex-direction: column;

  & > * + * {
    margin-top: 2rem;
  }
}
